<template>
  <v-footer id="footer" class="d-flex align-center justify-center" height="100" color="#100E39">
    <div class="d-block text-center">
      <div class="footer-tk-year">{{ new Date().getFullYear() }} - TurnKey Engenharia</div>
      <div class="footer-tk-copyright">Todos os direitos reservados ©</div>
      <div class="footer-tk-development">Desenvolvido por <a class="footer-link" href="#">TK Engenharia</a></div>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>

<style scoped>
#footer {
  font-family: "Montserrat", sans-serif;
  color: #E7E6FF;
}

.footer-link {
  color: #16B4F2;
  text-decoration: none;
}

.footer-tk-year {
  font-size: 12px;
  font-weight: bold;
}

.footer-tk-copyright {
  font-size: 12px;
  font-weight: normal;
}

.footer-tk-development {
  font-size: 8px;
}
</style>