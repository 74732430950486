<template>
  <div id="differentials-home">
    <wave-component color="#0d0c37"/>

    <div class="main-container">
      <h2 class="differentials-title text-center pb-4">Diferenciais</h2>

      <h1 class="text-center differentials-subtitle">Por quê a TK Engenharia?</h1>

      <v-container class="differentials-card">
        <v-row>
          <v-col cols="12" md="4" class="text-center" v-for="d in differentials" :key="d.id">
            <img class="differential-img" :src="require(`@/assets/differentials/${d.img}`)" alt="image">
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import WaveComponent from '@/components/WaveComponent.vue';

export default {
  name: "DifferentialsHome",
  components: {WaveComponent},
  data: () => ({
    differentials: [
      {
        id: 1,
        img: 'high-quality.png'
      },
      {
        id: 2,
        img: 'specialized-support.png'
      },
      {
        id: 3,
        img: 'no-bureaucracy.png'
      }
    ]
  })
}
</script>

<style>
.differentials-card {
  padding-top: 200px;
}

.differentials-title {
  color: #16B4F2;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.differentials-subtitle {
  color: #E7E6FF;
  font-size: 40px;
  letter-spacing: 3px;
  font-family: "Montserrat", sans-serif;
}

.differential-img {
  width: 100%;
  max-width: 360px;
}

@media screen and (max-width: 959px) {
  .differentials-subtitle {
    font-size: 20px !important;
    letter-spacing: 1px;
  }

  .differentials-card {
    padding-top: 60px;
  }

  .differential-img {
    padding-bottom: 40px;
  }
}
</style>
