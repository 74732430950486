<template>
  <div class="wave">
    <svg viewBox="0 0 1440 320" xmlns='http://www.w3.org/2000/svg' preserveAspectRatio="none">
      <path :fill="svgColor" fill-opacity="1"
            d="M0,256L80,256C160,256,320,256,480,218.7C640,181,800,107,960,90.7C1120,75,1280,117,1360,138.7L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"/>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true
    },
  },
  name: 'WaveComponent',
  computed: {
    svgColor() {
      return this.$vuetify.theme.themes.light[this.color] || this.color;
    }
  }
}
</script>

<style scoped>
.wave {
  height: 10rem;
}

.wave > svg {
  width: 100% !important;
  height: 100% !important;
}
</style>