import VueRouter from 'vue-router';

import Vue from 'vue';
import HomeView from '@/views/HomeView.vue';
import NotFoundView from '@/views/NotFoundView';

Vue.use(VueRouter);

const routes = [
    {
        path: '/', name: 'home', component: HomeView
    },
    {
        path: '*', name: 'notfound', component: NotFoundView
    }
];

const router = new VueRouter({mode: 'history', base: process.env.BASE_URL, routes});

export default router;
