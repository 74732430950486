<template>
  <div id="services-home">
    <wave-component color="primary"/>

    <div class="main-container">
      <h2 class="text-center service-title pb-5">Serviços</h2>
      <v-row class="pb-10" align="center" justify="center">
        <v-col id="services-bar" cols="12">
          <v-row class="text-center white--text align-center justify-center main-container">
            <v-col class="d-flex justify-center" cols="4" :key="service.id" v-for="service in services">
              <div :class="['btn-services', {active: carouselItem === service.id}]" @click="carouselItem = service.id"
                   :style="{backgroundColor: service.color}">
                {{ service.title }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-carousel v-model="carouselItem" height="auto"
                  :show-arrows="false"
                  hide-delimiter-background
                  hide-delimiters
                  touchless
      >
        <v-carousel-item :key="service.id" v-for="service in services" class="service-carousel-item">
          <service-card :services="service.items" :color="service.color"/>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
import WaveComponent from '@/components/WaveComponent.vue';
import ServiceCard from '@/views/home/ServiceCard.vue';

export default {
  name: 'ServicesHome',
  components: {ServiceCard, WaveComponent},
  data: () => ({
    carouselItem: 0,
    services: [
      {
        id: 0,
        title: 'TELECOM',
        color: '#fd6600',
        items: [
          {
            id: 1,
            title: 'Elaboração de projetos',
            icon: 'fibra.png',
            img: 'campo-tecnicos.png',
            services: [
              'Elaboração de projeto construtivo',
              'Elaboração de orçamentos de rede FTTx',
              'Elaboração de projetos de Compartilhamento de infraestrutura – Uso Mútuo'
            ]
          },
          {
            id: 2,
            title: 'Cabeamento e Infraestrutura',
            icon: 'fibra.png',
            img: 'campo-tecnicos.png',
            services: [
              'Cabeamento estruturado',
              'Execução de infraestrutura de redes subterrânea e aérea para rotas de cabos ópticos e metálicos',
              'Remanejamento de infraestrutura de rede aérea e subterrânea',
              'Montagem de infraestrutura para a instalação de Data Center: Construção de ambientes climatizados, sala de no breaks e geradores, sistema de combate a incêndio, cabeamento óptico, cabeamento de par metálico, cabeamento elétrico e ativos de infraestrutura de rede',
              'Conexões e Configuração de equipamentos'
            ]
          },
          {
            id: 3,
            title: 'Fibra óptica',
            icon: 'fibra.png',
            img: 'campo-tecnicos.png',
            services: [
              'Fusão de fibra óptica',
              'Certificação de rede óptica',
              'Montagem de caixas de emenda'
            ]
          }
        ]
      },
      {
        id: 1,
        title: 'DEV',
        color: '#126095',
        items: [
          {
            id: 1,
            title: 'Desenvolvimento de Softwares',
            icon: 'fibra.png',
            img: 'campo-tecnicos.png',
            services: [
              'Desenvolvimento de Aplicativos',
              'Desenvolvimento de Sistemas',
              'Criação de Sites',
              'Integração de sistemas'
            ]
          },
          {
            id: 2,
            title: 'Serviços de Nuvem',
            icon: 'fibra.png',
            img: 'campo-tecnicos.png',
            services: [
              'Virtualização e gerenciamento de cloud computing',
              'Backup e recuperação de dados em nuvem',
              'Hospedagem de sites'
            ]
          },
          {
            id: 3,
            title: 'Gerenciamento de servidores',
            icon: 'fibra.png',
            img: 'campo-tecnicos.png',
            services: [
              'Implantação e gestão de servidor de logs',
              'Implantação e gestão de servidor de e-mails'
            ]
          },
        ]
      },
      {
        id: 2,
        title: 'AUTOMAÇÃO',
        color: '#960000',
        items: [
          {
            id: 1,
            title: 'Automação residencial',
            icon: 'fibra.png',
            img: 'campo-tecnicos.png',
            services: [
              'Projeto e montagem de quadro de automação'
            ]
          }
        ]
      },
      {
        id: 3,
        title: 'ELÉTRICA',
        color: '#d6d000',
        items: [
          {
            id: 1,
            title: 'Projetos',
            icon: 'fibra.png',
            img: 'campo-tecnicos.png',
            services: [
              'Projetos elétricos residenciais, comerciais e industriais',
              'Dimensionamento de circuitos elétricos',
              'Instalação de sistemas de iluminação e tomadas',
              'Montagem de quadros elétricos'
            ]
          }
        ]
      },
      {
        id: 4,
        title: 'SEG. DO TRAB.',
        color: '#42741b',
        items: [
          {
            id: 1,
            title: 'Serviços',
            icon: 'fibra.png',
            img: 'campo-tecnicos.png',
            services: [
              'Serviços de Eng. Seg.'
            ]
          }
        ]
      }
    ]
  })
}
</script>

<style scoped>
#services-bar {
  max-width: 1000px;
}

#services-home {
  background-color: #0d0c37;
  font-size: 25px;
}

.btn-services {
  width: 250px;
  padding: 5px 5px;
  border-radius: 22px;
  opacity: 0.3;
  cursor: pointer;
}

.btn-services.active {
  opacity: 1;
}

.service-title {
  color: #E7E6FF;
  letter-spacing: 2px;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 1244px) {
  #services-bar {
    font-size: 10px;
    max-width: 800px;
  }

  .service-title {
    font-size: 25px;
  }

  .btn-services {
    width: 200px;
    font-size: 9px;
  }
}
</style>
