<template>
  <div class="img-mvv" :style="style"/>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    }
  },
  name: 'ImageMoving',
  data() {
    return {
      position: 0,
    }
  },
  mounted() {
    setInterval(() => this.position -= 1, 20);
  },
  computed: {
    style() {
      return {
        backgroundImage: `url('${require(`@/assets/${this.image}`)}')`,
        backgroundPosition: `${this.position}px 0`
      };
    }
  }
}
</script>

<style>
.img-mvv {
  background-size: auto 100%;
  background-repeat: repeat-x;
  height: 100%;
  width: 100%;
}
</style>