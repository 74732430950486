<template>
  <div class="primary">
    <site-header/>
    <main-home/>
    <services-home/>
    <differentials-home/>
    <partners-home/>
    <site-footer/>
  </div>
</template>

<script>
import MainHome from '@/views/home/MainHome.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import ServicesHome from '@/views/home/ServicesHome.vue';
import DifferentialsHome from '@/views/home/DifferentialsHome.vue';
import PartnersHome from "@/views/home/PartnersHome.vue";
import SiteFooter from "@/components/SiteFooter.vue";

export default {
  name: 'HomeView',
  components: {SiteFooter, PartnersHome, DifferentialsHome, ServicesHome, SiteHeader, MainHome},
}
</script>
